import React, {useReducer, useEffect, useState} from 'react'
import {
    Button,
    Text,
    Center,
    Flex,
    Alert,
    AlertIcon,
    Box,
    Image,
    Divider,
    Stack,
    VStack,
    HStack,
    Heading,
    CloseButton,
    Checkbox
} from "@chakra-ui/react"
import {reducer} from '../../reducers/reducer'
import Axios from 'axios'
import * as yup from 'yup';
import RegisterInput from './RegisterInput'
import LOGO from '../../assets/isola2.png'
import ReactDatePicker from 'react-datepicker';

import "../../DatePickStyle.css"
import "react-datepicker/dist/react-datepicker.css";

export default function LoginFormProspect({languageData}:any) {
    const [{data}, dispatch] = useReducer(reducer, {data: []})
    const [mismatch, setMismatch] = useState(false)
    const [agreedTerms, setAgreedTerms] = useState(false)
    const [message, setMessage] = useState('')
    const [sent, setSent] = useState(false)
    const [phone, setPhone] = useState('')
    const [nationality, setNationality] = useState('')
    const [selectedDate, setSelectedDate] = useState<any>('')
    const [isChinese, setIsChinese] = useState<boolean>(false)
    const [verifying, setVerifying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('')
    const [isStaff, setIsStaff] = useState<boolean>(true);

    const [refID, setRefData] = useState('test');
    const [loggedIn, setLoggedIn] = useState(false);


    async function receiveMessageFromIndex ( event:any ) {
        console.log(await event.data)
        if (event.data.length == 4) {
            console.log(await event.data)
          setRefData(await event.data)
        }
      }
      useEffect(() => {
        function waitForIt() {
          
          return window.addEventListener("message", receiveMessageFromIndex, false);
        }
      
        waitForIt();
      
        async function getData() {
          await Axios.post('https://vt-api.isolaklcc.com/getreferrer', {code: refID}).then((data) => setRefData(data.data))
        }
        if (refID) {
          getData()
        }
      }, [refID])
      
        
      function CloseModal() {
          let info = {
              close: true,
              isStaff,
              origin: "https://virtual-tour.isolaklcc.com/login"
          }
        window.parent.postMessage(info, "*")
      }



    let schema = yup.object().shape({
        email: yup.string().email().required(languageData.errors.email),
        password: yup.string().min(8).required(languageData.errors.password),
        referral: yup.string().nullable(),
    })

    let schemaCN = yup.object().shape({
        email: yup.string().email().required(languageData.errors.email),
        password: yup.string().min(8).required(languageData.errors.password),
        referral: yup.string().nullable(),
    })

    async function LogToAccount() {

        let email = data.email
        let password = data.password
        
            await Axios.post(`https://vt-api.isolaklcc.com/login-prospect`, {
                email, password
              }).then((response) => {
                  console.log(response.data)
                if (response.data.auth) {
                  localStorage.setItem("token",response.data.token)
                  CloseModal()
                  setLoggedIn(true);
                } else {
                    setErrorMessage(response.data.message)
                }
              })
    }

    async function LogToAccountAgent() {

        let agentEmail = data.email
        let agentPassword = data.password
        
            await Axios.post(`https://vt-api.isolaklcc.com/login-agent`, {
                agentEmail, agentPassword
              }).then((response) => {
                  console.log(response.data)
                if (response.data.auth) {
                  localStorage.setItem("token",response.data.token)
                  CloseModal()
                  setLoggedIn(true);
                } else {
                    setErrorMessage(response.data.message)
                }
              })
    }



    function AppointBooking({
        selectedDate,
        onChange,
        email,
        isClearable = false,
        showPopperArrow = false,
        ...props
      }: any) {


        async function AppointBooking() {
            await Axios.post('https://vt-api.isolaklcc.com/appointment', {email, appointment: selectedDate.toString()}).catch((err) => {
            // await Axios.post('http://localhost:3000/appointment', {email, appointment: selectedDate.toString()}).catch((err) => {
                console.log(err)
            }).then((res:any) => {
                console.log(res)
                if (res.data.booked) {
                    setSuccessMessage(res.data.message)
                }
            })
        }


        return (
            <VStack>
                            {successMessage.length !== 0 ? <Alert status="success">
                <AlertIcon />
                {successMessage}
            </Alert>  : null}
                              
                 <Image pb="10" maxW="200px" src={LOGO} alt="logo" />
                 <Heading>Limited Access View</Heading>
                <Text textAlign="center" w="60%">It is recommended to go through it with our staff. Let us know when it is convenient to call you.</Text>
            <ReactDatePicker
            selected={data.appointment}
            showTimeSelect
            onChange={onChange}
            timeIntervals={60}
            isClearable={isClearable}
            dateFormat="PPpp"
            showPopperArrow={showPopperArrow}
            className="react-datapicker__input-text"//input is white by default and there is no already defined class for it so I created a new one
            {...props}
          />
                          {successMessage.length > 0 ? <Button disabled={true}  _hover={{ bg: "#3D4962", color:"white"}} bg="#3D4962" color="white" w="60%">Send</Button> : <Button onClick={AppointBooking}  _hover={{ bg: "#3D4962", color:"white"}} bg="#3D4962" color="white" w="60%">Send</Button>}
                          <Button onClick={CloseModal} _hover={{ bg: '#E53E3E', color:"white"}}   w="30%">Close</Button>
          
          </VStack>

        )
    }


    useEffect(() => {
        dispatch({type: 'selected-date', appointment: selectedDate})

    }, [selectedDate])




    useEffect(() => {

        if (!isChinese) {
            schema.isValid({
                email: data.email,
                password: data.password,
                referral: data.referral
            })
            .then((valid) => {
                if (!mismatch && valid) {
                    console.log('completed')
                }
                if (mismatch || !valid) {
                    console.log('incomplete')
                }
            })
        } else {
            schemaCN.isValid({
                email: data.email,
                password: data.password,
                referral: data.referral
            })
            .then((valid) => {
                if (!mismatch && valid) {
                    console.log('completed')
                }
                if (mismatch || !valid) {
                    console.log('incomplete')
                }
            })
        }


      }, [data, mismatch, agreedTerms, nationality])


       return (
           <Center  bg="rgba(255, 255, 255, 0.90)" boxShadow="xl"  borderRadius="md" h="70%" w={["100%","100%","100%","50%"]}>   

           {loggedIn ? <AppointBooking email={data.email} label={languageData.registerPage.preferredAppoint} selectedDate={data.appointment} onChange={(date:any) => setSelectedDate(date)}/> : 
           
           <Stack  justifyContent="center" alignItems="center" h="100%" px="5%" py="15%" w="100%" color="#2D4D6E">
              {errorMessage.length !== 0 ? <Alert status="error">
                <AlertIcon />
                {errorMessage}
            </Alert>  : null}

            <Image pb="10" maxW="250px" src={LOGO} alt="logo" />

            {isStaff ? <Text fontSize="2rem" fontWeight="medium" as={"h1"}>Login As OCR Agent</Text> : <Text fontSize="2rem" fontWeight="medium" as={"h1"}>Login</Text>}
                <Text textAlign="center"  w="100%">Welcome to OCR: Isola KLCC Virtual Tour</Text>
                <RegisterInput type={'email'} dispatch={(e:any) => dispatch({type: 'email', email:e.target.value})} label="Email" />
                <RegisterInput type={'password'} dispatch={(e:any) => dispatch({type: 'password', password:e.target.value})} label="Password" />
                <Flex justifyContent="center" alignContent="center" w={["100%", "100%", "65%", "75%"]}>
                    {/* <Checkbox checked={isStaff} onChange={() => setIsStaff(!isStaff)} w={["100%", "100%", "25vw", "25vw"]}>Staff Login</Checkbox> */}
                    </Flex>


                <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>
                <Divider/>




            <Flex  justifyContent={["center", "center", "left", "left"]}>{isStaff ? <Button w="250px" onClick={LogToAccountAgent} _hover={{ bg: '#FC8181', color:"white"}} bg="#3D4962" color="white">Login as OCR Agent</Button> : <Button w="250px" onClick={LogToAccount} _hover={{ bg: '#FC8181', color:"white"}} bg="#3D4962" color="white">Login</Button>}</Flex>
        </Stack>
        
        
        }


        </Center>
    )
}
