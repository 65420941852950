import React from "react";
import { Box} from '@chakra-ui/react'
import LandingLayout from "../components/layouts/LandingLayout";
import Header from "../components/sections/Header";
import ProspectForm from "./reg-comps/ProspectForm";
import LoginFormProspect from "./log-comps/LoginFormProspect";

export default function LoginProspectRef({languageData}:any) {
  return (
      <LandingLayout>
        <Header/>
        <Box d="flex" justifyContent="center" h={["100%","100%","100vh","100vh"]}  w="100%">
          <LoginFormProspect languageData={languageData} />

        </Box>
      </LandingLayout>
  );
}
