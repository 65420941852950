import React, {useReducer, useEffect, useState} from 'react'
import {
    Button,
    Text,
    Center,
    Flex,
    Stack,
    Alert,
    AlertIcon,
    Link,
    Box,
    Divider
} from "@chakra-ui/react"
import RegisterInput from "../registerComp/RegisterInput"
import TermPopUp from '../registerComp/TermPopUp'
import {reducer} from '../reducers/reducer'
import Axios from 'axios'
import * as yup from 'yup';
import InputFormMobile from '../registerComp/InputMobile'
import OTP from '../registerComp/otp/OTP'
import LandingLayout from '../components/layouts/LandingLayout'
import Header from '../components/sections/Header'


export default function TemLink({languageData}:any) {
    const [verifying, setVerifying] = useState(false);
    const [refID, setRefData] = useState(null)
    const [referral, setReferral] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)


    async function receiveMessageFromIndex ( event:any ) {
        console.log(await event.data)
        if (event.data.length == 6) {
            console.log(await event.data)
          setRefData(await event.data)
        }
      }
      
      useEffect(() => {
        function waitForIt() {
          
          return window.addEventListener("message", receiveMessageFromIndex, false);
        }
      
        waitForIt();
      
        // async function getData() {
        //   await Axios.post('http://localhost:3000/getreferrer', {code: refID}).then((data) => setRefData(data.data))
        // }
        if (refID) {
            console.log(refID)
            checkLink(refID)
        }
      }, [refID])
      
        
      function CloseModal() {
          let info = {
              close: true,
              origin: "checkLink"
          }
        window.parent.postMessage(info, "*")
      }


      async function checkLink(id: string | null) {
          Axios.post('https://vt-api.isolaklcc.com/get-temp-link', {id}).catch((err) => {
              if (err) throw err;
          }).then((res:any) => {
              console.log()
              setMessage(res.data.message)
              setVerifying(res.data.status)
              setError(res.data.error)
              if (res.data.referral) {
                  setReferral(res.data.referral)
              }
          })
      }

      useEffect(() => {
          if (verifying) {
              CloseModal()
          }
      }, [verifying])





       return (
           <LandingLayout>
               <Header/>
               <Box d="flex" justifyContent="center" h={["100%","100%","100vh","100vh"]}  w="100%">

           <Center  bg="rgba(255, 255, 255, 0.90)" boxShadow="xl"  borderRadius="md" h="80%" w={["100%","100%","100%","60%"]}>
           {verifying ?         
        <Stack  justifyContent="center" alignItems="center" h="100%" px="5%" py="15%" w="100%" color="#2D4D6E">


        <Text fontSize="2rem" fontWeight="medium" as={"h1"}>{message}</Text>

  
            <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>
            <Divider/>
  
        <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>
  
  
    </Stack>
        :         
        <Stack  justifyContent="center" alignItems="center" h="100%" px="5%" py="15%" w="100%" color="#2D4D6E">


      <Text fontSize="2rem" fontWeight="medium" as={"h1"}>{message}</Text>
      {error ? null : <Link 
        isExternal={true} 
        href={`https://virtual-tour.isolaklcc.com/register-prospect/${referral}`}>
            <Button>
                Register
            </Button>
            </Link>}

          <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>
          <Divider/>

      <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>


  </Stack>

        
        }

        </Center>
        </Box>
        </LandingLayout>

    )
}
