import React from 'react'
import {
    Text,
    InputGroup,
    VStack,
    Stack,
    InputRightElement
} from "@chakra-ui/react"

export default function DatePickerInput({ label, onChange, selectedDate, DatePick }: any) {

    return (
        <VStack w={["100%", "100%", "65%", "75%"]}>
        <Stack  direction={['column', 'column', 'row', 'row']} d="flex" alignItems="center" justifyContent="space-between">
            <Text w={["100%", "100%", "25vw", "25vw"]} fontWeight="semibold" textAlign="left">{label}<span style={{color:"red"}}>*</span></Text>
            <InputGroup>
            <InputRightElement ></InputRightElement>
            <DatePick onChange={onChange} selectedDate={selectedDate}/>
            </InputGroup>
        </Stack>
        </VStack>
    )
}
