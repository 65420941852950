import React, {useState, useEffect} from 'react'
import {
  InputGroup,
  Input,
  InputRightElement,
  Text, 
  VStack,
  Stack,

} from "@chakra-ui/react"

export default function PasswordInput({label, dispatch,data, mismatch, setMismatch, confirmPW, mismatchPW}: any) {
  // const [show, setShow] = useState(false)
  // const handleClick = () => setShow(!show)
  const [confirmPw, setConfirmPw] = useState('')


  useEffect(() => {

    if (confirmPw) {
      if (data.password && data.password !== confirmPw ) {
        setMismatch(true)
  
      } else {
        setMismatch(false)
      }
    }



  }, [data, confirmPw])


  return (
    <VStack spacing="2"   w={["100%", "100%", "65%", "75%"]}>
          <Stack direction={['column', 'column', 'row', 'row']}>
      <Text d="flex" alignItems="center"  w={["100%", "100%", "25vw", "25vw"]}  fontWeight="semibold" textAlign="left">{label}<span style={{color:"red"}}>*</span></Text>
      <InputGroup >
        <Input type={"password"} _hover={{ borderColor: "#2D4D6E" }} onChange={dispatch} borderColor="#2D4D6E" h="3rem"  w={["80vw", "80vw", "45vw", "30vw"]} />

      </InputGroup>
    </Stack>
    <Stack  direction={['column', 'column', 'row', 'row']}>
      <Text d="flex" alignItems="center"  w={["100%", "100%", "25vw", "25vw"]} fontWeight="semibold" textAlign="left">{confirmPW}<span style={{color:"red"}}>*</span></Text>
      <InputGroup>
      <Input value={confirmPw} type="password" onChange={(e) => setConfirmPw(e.target.value)} _hover={{ borderColor: "#2D4D6E" }}  borderColor="#2D4D6E" h="3rem" w={["80vw", "80vw", "45vw", "30vw"]} />
      <InputRightElement>
      <Text  d={mismatch ? ('inherit') : ('none')}  right="30vw" color="red">{mismatchPW}</Text>
</InputRightElement>
      </InputGroup>

      </Stack>

    </VStack>


  )
}