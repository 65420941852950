import React, {useEffect} from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Checkbox,
  Text,
} from "@chakra-ui/react"

export default function TermPopUp({setAgreedTerms, languageData}: any) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isCheckBoxChecked, setChecked] = React.useState(false);

  const agreeButtonHandler = () => {
    setChecked(true)
  }

  useEffect(() => {
    setAgreedTerms(isCheckBoxChecked)
  }, [isCheckBoxChecked])

  return (
    <>
      <Flex bg="#3D4962" borderRadius="0.5rem" p="2" w="100%">
        <Checkbox colorScheme="whiteAlpha" onChange={() => {
          setChecked(!isCheckBoxChecked)
        }} isChecked={isCheckBoxChecked}/>

        <Text pl="2"> <span onClick={onOpen} style={{ color: 'white', cursor: "pointer" }}><u>{languageData.agree}</u></span></Text>
        </Flex>
      
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{languageData.termsandConditions}</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="justify">
            {languageData.terms}
            <br></br><br></br>
            {languageData.privacy}
            </ModalBody>

          <ModalFooter>
          {/* <Button id="agree" colorScheme="red" mr={3} onClick={() => {setChecked((old) => !old); onClose()}}> */}
            <Button id="agree" bg="#3D4962" mr={3} color="white" onClick={() => {agreeButtonHandler(); onClose()}}>
                {languageData.agreed}
              </Button>
            <Button id="decline" variant="ghost" onClick={onClose}>{languageData.cancelAgree}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}