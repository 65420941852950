import React from "react";
import { IconButton, Image } from "@chakra-ui/react";
import ClipboardIcon from "../assets/clipboard.png";
import SuccessIcon from "../assets/clipboard_confirm.png";
import useCopyToClipboard from "./useCopyToClipboard";

export default function CopyButton({ code }:any) {
  const [isCopied, handleCopy] = useCopyToClipboard();

  return (
    <IconButton w="1rem" aria-label="copy" onClick={(e:any) => handleCopy(code)} icon={isCopied ? <Image src={SuccessIcon}/> : <Image src={ClipboardIcon}/>} />
      
  );
}