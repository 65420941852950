import React, {useState, useEffect} from 'react'
import { Text, Center, VStack,  HStack, InputGroup, Heading, Button, PinInput, PinInputField, Alert,
    AlertIcon,} from '@chakra-ui/react'
import Axios from 'axios'

export default function FinalRegistered({data, register, message, setIsCorrect, isCorrect, languageData, CloseModal}:any) {
    const [OTP, setOTP] = useState('')
    const [inputOTP, setInputOTP] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [afterWaiting, setAfterWaiting] = useState(false)
    const [resendCounter, setResendCounter] = useState(0)

    useEffect(() => {
        if (data && data.phone) {
            register()
        }
        timer()
    }, [data])


    async function getOTP() {
        await Axios.get(`https://vt-api.isolaklcc.com/verifyy-otp`, {
            params: {
                ID: data.phone
            }
        }).catch((err:any) => {
            console.log(err)
        }).then((res:any) => {
            setOTP(res.data.otp)
        })
    }


    function timer() {
        setTimeout(function(){ setAfterWaiting(true); }, 25000);
    }

    function timerClose() {
        setTimeout(function(){ CloseModal(); }, 5000);
    }

    function resend() {

        if (resendCounter < 4) {
                            if (data) {
            Axios.get(`https://vt-api.isolaklcc.com/verifyy-otp`, {
                params: {
                    ID: data.phone
                }
            }).catch((err:any) => {
                console.log(err)
            }).then((res:any) => {
                setOTP(res.data.otp)
            })
        }


        setAfterWaiting(false)
        timer()
        let counter = resendCounter + 1
        setResendCounter(counter)

        } else {
            setAfterWaiting(false)
            setErrorMessage(languageData.misc.otp)
        }

    }

    function verifyOTP(e:any) {
        e.preventDefault();

        if (inputOTP.length == 4) {
            if (inputOTP == OTP) {
                register();   
                setIsCorrect(true);
            } else {
                setErrorMessage('Incorrect OTP')
            }

        } else {
            setErrorMessage('Incorrect OTP')
        }  
    }
    return (
        <Center p="20" flexDir="column" d='flex' textAlign="center" justifyContent="center" alignItems="center" >
             <Heading as="h2" >
                 {message}
            </Heading>
            </Center>
    )
}
