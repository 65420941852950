import React from 'react'
import {
    Text,
    Input,
    InputGroup,
    VStack,
    Stack,
    InputRightElement
} from "@chakra-ui/react"
// import { PhoneIcon } from "@chakra-ui/icons";

export default function RegisterInput({ label, type, hasLeftIcon, dispatch }: any) {

    return (
        <VStack  w={["100%", "100%", "65%", "75%"]}>
        <Stack w="80%" direction={['column', 'column', 'row', 'row']} d="flex" alignItems="center" justifyContent="center">
            <Text w={["100%","100%","50%","50%"]}   fontWeight="semibold" textAlign="left">{label}<span style={{color:"red"}}>*</span></Text>
            <InputGroup>

            <Input  type={type} _hover={{ borderColor: "#2D4D6E" }} onChange={dispatch} borderColor="#2D4D6E" w="100%" h="3rem" />
            </InputGroup>
        </Stack>
        </VStack>
    )
}
