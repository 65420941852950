import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  toVG,
  ...rest
}:any) {
const history = useHistory()
  return (
    <>
    <Flex
    height={["80vh","80vh","85vh","85vh"]}

      align="center"
      justifyContent="center"
      alignItems="center"
      w="100%"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "column" }}
      wrap="no-wrap"

      
      {...rest}
    >
      <Stack
      justifyContent="center"
      alignItems={["center", "center","flex-start","flex-start"]}
      d="flex"
      h="100%"
        w="100%"
        pl={["0","0","15%","15%"]}
      >
        <Heading
          as="h1"
          textShadow="0px 4px 4px rgba(0, 0, 0, 0.6);"
          fontWeight="bold"
          color="white"
          fontSize={["1.5rem","x-large","xx-large", "2.6rem"]}
          textAlign={["center", "center", "center", "left"]}
          w={["80%","60%","40%","35%"]}

        >
          {title}
        </Heading>
          {/* <Button
          onClick={() => history.push('/register')}
          background="linear-gradient(179.95deg, #C4C4C4 0.05%, rgba(0, 0, 0, 0.4) 99.95%)"
          color="white"
          _hover={{color: "white"}}
            borderRadius="8px"
            py="8"
            px="4"
            fontSize={["1.3rem","1.5rem","1.4rem", "1.4rem"]}
            lineHeight="1"
            w={["80%","60%","40%","35%"]}
          >
            {ctaText}
          </Button> */}
          <Button
          onClick={() => window.open('http://isola-vg-view.s3-website-ap-southeast-1.amazonaws.com/', '_blank')}
          background="linear-gradient(179.95deg, #C4C4C4 0.05%, rgba(0, 0, 0, 0.4) 99.95%)"
          color="white"
          _hover={{color: "white"}}
            borderRadius="8px"
            py="8"
            px="4"
            fontSize={["1.1rem","1.3rem","1.2rem", "1.2rem"]}
            lineHeight="1"
            w={["80%","60%","40%","35%"]}>{toVG}</Button>
      </Stack>
      <Flex pb="3%" display={["none", "none", "inherit", "inherit"]} fontSize={["1rem", "1rem", "1rem", "1.05rem"]}  justifyContent="space-evenly" color="white" alignItems="flex-end" height="100%" w="80%"><Text textAlign="center">{subtitle.usp.one}</Text><Text textAlign="center">|</Text><Text textAlign="center">{subtitle.usp.two}</Text><Text textAlign="center">|</Text><Text textAlign="center">{subtitle.usp.three}</Text><Text textAlign="center">|</Text><Text textAlign="center">{subtitle.usp.four}</Text></Flex>

    </Flex>

    </>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.object,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string
};

Hero.defaultProps = {
  // title: "React landing page with Chakra UI",
  // subtitle:
  //   "This is the subheader section where you describe the basic benefits of your product",
  // image: "https://source.unsplash.com/collection/404339/800x600",
  // ctaText: "Create your account now",
  ctaLink: "/register"
};
