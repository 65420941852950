
export function reducer(state:any,action:any) {
    switch (action.type) {
      case "full-name":
        return {
          data: {...state.data, fullName: action.fullName}
        }
      case "email":
          return {
              data: {...state.data, email: action.email}
          }
      case "phone":
          return {
              data: {...state.data, phone: action.phone}
          }
      case "password":
          return {
                data: {...state.data, password: action.password}
          }
      case "wechat":
          return {
                data: {...state.data, wechat: action.wechat}
          }
      case "selected-date":
          return {
                data: {...state.data, appointment: action.appointment}
          }
      case "nationality":
          return {
                data: {...state.data, nationality: action.nationality}
            }
      case "refId":
          return {
                data: {...state.data, referral: action.referral}
            }
          
        default: 
        return state;
    }
  }