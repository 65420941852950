import { Text, InputGroup, Select, VStack, Stack, InputRightElement} from '@chakra-ui/react'
import { getCountryTelCode, COUNTRIES } from "./countries";
import React,{useState, useEffect, useRef} from 'react'


interface inputProps {
    inputTitle: string;
    prop: string;
    setProp: any;
    inputPlaceholder?: string;
    title: string;
    select: string
}

export default function InputCountry({inputTitle, setProp, prop, select}: inputProps) {
    const [selectedCountry, setSelectedCountry] = useState("MYS");
    const [countryCode, setCountryCode] = useState("+60");

    const countryOptions = COUNTRIES.map(({ name, iso }:any) => (
      {
        label: name,
        value: iso
      }
      ))

      const options = countryOptions.filter(({ label, value }:any) => {

        if (value !==  "MYS") {

          return({
              label,
              value
            })
        }
      })

  
      const onCountryChange = (e: { target: { value: any; }; }) => {
        
        let value = e.target.value;
        setProp(value);
      };

      useEffect(() => {
        getCountryTelCode(selectedCountry)
        setCountryCode(getCountryTelCode(selectedCountry))
      }, [selectedCountry])


    return (
      <VStack w={["100%", "100%", "65%", "75%"]}>
        <Stack d="flex" alignItems="center"   direction={['column', 'column', 'row', 'row']}>
            <Text   w={["100%", "100%", "25vw", "25vw"]} fontWeight="semibold" textAlign="left" >{inputTitle}<span style={{color:"red"}}>*</span></Text>
            <InputGroup>
            <InputRightElement></InputRightElement>
            
            <Select
            placeholder={select}
            borderColor="#2D4D6E"
          value={prop}
          h="3rem"
          w={["80vw", "80vw", "45vw", "30vw"]}
          onChange={onCountryChange}
        >
          <option key={0} value="Malaysia">Malaysia</option>
          {options.map((option: { value: any; label: any }, index:number) => (
            <option key={index} value={option.label}>{option.label}</option>
          ))}
        </Select >
            </InputGroup>
            

        </Stack>
        </VStack>
    )
}
