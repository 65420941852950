import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <Router>
    <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
)


serviceWorker.unregister()

reportWebVitals()
