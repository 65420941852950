import React from "react";
import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";
import Header from "../components/sections/Header";
import { Box, Flex, Button } from '@chakra-ui/react'


export default function Landing({ languageData }: any) {
  return (
    <LandingLayout>
      <Box h="100vh">
        <Header />
        <Hero
          title={languageData.intro}
          subtitle={languageData}
          ctaText={languageData.createAcc2}
          ctaLink="/register"
          toVG={languageData.directToVG}
        />
      </Box>

    </LandingLayout>
  );
}
