import React from "react";
import {Center, Box} from '@chakra-ui/react'
import LandingLayout from "../components/layouts/LandingLayout";
import RegisterForm from "../registerComp/RegisterForm";
import Header from "../components/sections/Header";
import AgentForm from './reg-comps/AgentForm'
import ProspectForm from "./reg-comps/ProspectForm";

export default function RegisterProspectRef({languageData}:any) {
  return (
      <LandingLayout>
        <Header/>
        <Box d="flex" justifyContent="center" h={["100%","100%","100vh","100vh"]}  w="100%">
          <ProspectForm languageData={languageData}/>
        </Box>
      </LandingLayout>
  );
}
