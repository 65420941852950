import React from "react";
import { Flex } from "@chakra-ui/react";
import BG from '../../assets/BG.jpg'

export default function LandingLayout(props:any) {

  return (
    <>
    <Flex
    backgroundImage={BG}
      direction="column"
      h="100%"

      {...props}
    >
      {props.children}
    </Flex>
    </>
  );
}
