import React, {useReducer, useEffect, useState} from 'react'
import {
    Button,
    Text,
    Input,
    Center,
    Flex,
    HStack,
    Stack,
    Alert,
    AlertIcon,
    Box,
    Divider
} from "@chakra-ui/react"
import RegisterInput from "../../registerComp/RegisterInput"
import PasswordInput from "../../registerComp/PasswordInput"
import TermPopUp from '../../registerComp/TermPopUp'
import {reducer} from '../../reducers/reducer'
import Axios from 'axios'
import * as yup from 'yup';
import DatePick from '../../registerComp/DatePick'
import InputFormMobile from '../../registerComp/InputMobile'
import DatePickerInput from '../../registerComp/DatePickerInput'
import InputCountry from '../../registerComp/InputCountry'
import OTP from '../../registerComp/otp/OTP'
import {useParams} from 'react-router-dom'
import CopyButton from '../../utils/CopyButton'


export default function ProspectForm({languageData}:any) {
    const [{data}, dispatch] = useReducer(reducer, {data: []})
    const [mismatch, setMismatch] = useState(false)
    const [agreedTerms, setAgreedTerms] = useState(false)
    const [message, setMessage] = useState('')
    const [sent, setSent] = useState(false)
    const [phone, setPhone] = useState('')
    const [nationality, setNationality] = useState('')
    const [selectedDate, setSelectedDate] = useState<any>('')
    const [isChinese, setIsChinese] = useState<boolean>(false)
    const [verifying, setVerifying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [isCorrect, setIsCorrect] = useState(false);
    const [wechat, setWechatID] = useState('')
    const [shortcode, setShortCode] = useState(null)

    const {id}:any = useParams();



    const [refID, setRefData] = useState(null)


    useEffect(() => {
        if (id.length > 3) {
            console.log(id)
            setRefData(id)
        }
    }, [id])

    // async function receiveMessageFromIndex ( event:any ) {
    //     console.log(await event.data)
    //     if (event.data.length == 4) {
    //         console.log(await event.data)
    //       setRefData(await event.data)
    //     }
    //   }
      
    //   useEffect(() => {
    //     function waitForIt() {
          
    //       return window.addEventListener("message", receiveMessageFromIndex, false);
    //     }
      
    //     waitForIt();
      
    //     async function getData() {
    //       await Axios.post('http://localhost:3000/getreferrer', {code: refID}).then((data) => setRefData(data.data))
    //     }
    //     if (refID) {
    //       getData()
    //     }
    //   }, [refID])
      
        
    //   function CloseModal() {
    //       let info = {
    //           close: true,
    //           origin: "http://localhost:3006"
    //       }
    //     window.parent.postMessage(info, "*")
    //   }



    let schema = yup.object().shape({
        fullName: yup.string().required(languageData.errors.name),
        email: yup.string().email().required(languageData.errors.email),
        phone: yup.string().required("Contact number is required."),
        // password: yup.string().min(8).required(languageData.errors.password),
        // nationality: yup.string().required(languageData.errors.nationality),
        referral: yup.string().nullable(),
        agreedTerms: yup.boolean().required(languageData.errors.tnc)
        .oneOf([true], languageData.errors.tnc),
    })

    let schemaCN = yup.object().shape({
        fullName: yup.string().required(languageData.errors.name),
        email: yup.string().email().required(languageData.errors.email),
        phone: yup.string().required("Contact number is required."),
        // password: yup.string().min(8).required(languageData.errors.password),
        // wechat: yup.string().required(languageData.errors.wechat),
        // nationality: yup.string().required(languageData.errors.nationality),
        referral: yup.string().nullable(),
        agreedTerms: yup.boolean().required(languageData.errors.tnc)
        .oneOf([true], languageData.errors.tnc),
    })

    async function verify() {
        
        if (!isChinese) {
            await schema.validate({
                fullName: data.fullName,
                email: data.email,
                phone: data.phone,
                // password: data.password,
                agreedTerms: agreedTerms,
                // nationality: data.nationality,
                // referral: data.referral
                referral: refID
            }).catch((err)=> {
                setErrorMessage(err.errors[0])
                return null;
            }).then((validatedData) => {
                if (validatedData !== null) {
                    setVerifying(true)
                }
            })
    
        } else {
            await schemaCN.validate({
                fullName: data.fullName,
                email: data.email,
                // password: data.password,
                // wechat: data.wechat,
                phone: data.phone,
                agreedTerms: agreedTerms,
                // nationality: data.nationality,
                referral: refID
            }).catch((err)=> {
                setErrorMessage(err.errors[0])
                return null;
            }).then((validatedData) => {
                if (validatedData !== null) {
                    setVerifying(true)
                }
            })
    
        }
    }

    async function register() {

        if (!isChinese) {
            await schema.validate({
                fullName: data.fullName,
                email: data.email,
                // password: data.password,
                phone: data.phone,
                agreedTerms: agreedTerms,
                // nationality: data.nationality,
                // referral: id
                referral: refID
            }).catch((err)=> {
                console.log(err.errors[0])
            }).then(async (validatedData) => {
                await Axios
                .post(`https://vt-api.isolaklcc.com/register`, validatedData)
                .then((res:any) => {
                    if (res.data.error) {
                        setMessage(res.data.message)             
                    } else {
                        console.log(res.data)
                        setMessage(res.data.message)
                        setShortCode(res.data.shortcode)
    
                    }
                })
            })
    
        } else {
            await schemaCN.validate({
                fullName: data.fullName,
                email: data.email,
                // password: data.password,
                // wechat: data.wechat,
                phone: data.phone,
                // appointment: data.appointment,
                agreedTerms: agreedTerms,
                // nationality: data.nationality,
                // referral: data.referral
                referral: refID
            }).catch((err)=> {
                console.log(err.errors[0])
            }).then(async (validatedData) => {
                await Axios
                .post(`https://vt-api.isolaklcc.com/register`, validatedData)
                .then((res:any) => {
                    setSent(true);
                    if (res.data.error) {
                        setMessage(res.data.message)             
                    } else {
                        setMessage(res.data.message)
    
                    }
                })
            })
    
        }



    }

    useEffect(() => {

        if (phone.includes('+86') || nationality == 'China') {
            setIsChinese(true)
        } else {
            setIsChinese(false)
            dispatch({type: 'wechat', wechat: null})
        }
         
        dispatch({type: 'phone', phone: phone})
        dispatch({type: 'selected-date', appointment: selectedDate})
        dispatch({type: 'nationality', nationality: nationality})
        dispatch({type: 'wechat', wechat: wechat})
        dispatch({type: 'refId', referral: id})
       
        
    }, [phone, selectedDate, nationality, wechat])



    useEffect(() => {

        if (!isChinese) {
            schema.isValid({
                fullName: data.fullName,
                email: data.email,
                // password: data.password,
                phone: data.phone,
                agreedTerms: agreedTerms,
                // appointment: data.appointment,
                // nationality: data.nationality
            })
            .then((valid) => {
                if (!mismatch && valid) {
                    console.log('completed')
                }
                if (mismatch || !valid) {
                    console.log('incomplete')
                }
            })
        } else {
            schemaCN.isValid({
                fullName: data.fullName,
                email: data.email,
                // password: data.password,
                // wechat: data.wechat,
                phone: data.phone,
                agreedTerms: agreedTerms,
                // appointment: data.appointment,
                // nationality: data.nationality
            })
            .then((valid) => {
                if (!mismatch && valid) {
                    console.log('completed')
                }
                if (mismatch || !valid) {
                    console.log('incomplete')
                }
            })
        }


      }, [data, mismatch, agreedTerms, nationality])


       return (
           <Center  bg="rgba(255, 255, 255, 0.90)" boxShadow="xl"  borderRadius="md" h="80%" w={["100%","100%","100%","60%"]}>
           {verifying ?         
           <OTP shortcode={shortcode} languageData={languageData} isCorrect={isCorrect} setIsCorrect={setIsCorrect} message={message} register={register} data={data}/>
        :         
        <Stack  justifyContent="center" alignItems="center" h="100%" px="5%" py="15%" w="100%" color="#2D4D6E">
              {errorMessage.length !== 0 ? <Alert status="error">
                <AlertIcon />
                {errorMessage}
            </Alert>  : null}

            <Text fontSize="2rem" fontWeight="medium" as={"h1"}>Register</Text>
                <Text  w="100%">{languageData.registerPage.setUp}</Text>
                <RegisterInput type={'name'} dispatch={(e:any) => dispatch({type: 'full-name', fullName:e.target.value})} label={languageData.registerPage.fullName} />
                <InputFormMobile  inputTitle={languageData.registerPage.mobile} title='mobile' inputPlaceholder="Eg. 1234567" setProp={setPhone}/>
                {/* <InputCountry select={languageData.selectCountry} inputTitle={languageData.registerPage.nationality}  title={languageData.registerPage.nationality} prop={nationality} setProp={setNationality}/> */}
                {isChinese ? <RegisterInput type={'name'} dispatch={(e:any) => setWechatID(e.target.value)} label="WeChat ID" /> : null}
                <RegisterInput type={'email'} dispatch={(e:any) => dispatch({type: 'email', email:e.target.value})} label={languageData.registerPage.email} />
                {/* <PasswordInput confirmPW={languageData.registerPage.confirmPW} mismatchPW={languageData.errors.passwordMismatch} mismatch={mismatch} setMismatch={setMismatch} label={languageData.registerPage.password} data={data} dispatch={(e:any) => dispatch({type: 'password', password:e.target.value})} /> */}

                <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>
                <Divider/>

            <TermPopUp languageData={languageData} setAgreedTerms={setAgreedTerms}/>
            <Box borderBottomStyle="solid"  py="2" w="100%" h="10px"></Box>


            <Flex  justifyContent={["center", "center", "left", "left"]}><Button onClick={verify} _hover={{ bg: '#FC8181', color:"white"}} bg="#3D4962" color="white">Proceed</Button></Flex>

        </Stack>}

        </Center>
    )
}
