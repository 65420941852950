import { m } from "framer-motion"

export function en() {




    let en = {
      intro: `Welcome To Isola KLCC Virtual Tour`,
        createAcc: "Create Account",
        login: "Login",
        createAcc2: "Create Your Account Now",
        directToVG: "Check Out Our Virtual Gallery!",
        register: "Register",
        usp: {
          one: "FREEHOLD SERVICED RESIDENCE",
          two: "EXCLUSIVE 140 UNITS",
          three: "400M TO KLCC",
          four: "FULLY AUTOMATED CARPARK SYSTEM"
        },
        privacy: "By completing this form, I hereby understand, acknowledge and expressly give consent to O&C Makok Isola Sdn Bhd, its group of companies and their business partners to collect and process my personal data for their records to enable them to keep me informed of any updates / information with regards to O&C Makok Isola Sdn Bhd events and any other future events, products, services and marketing purposes so long as the use of such information does not constitute an invasion of my privacy.",
        agree: "I agree to OCR Bhd's terms and privacy policy.",
        verify: "Verify Account",
        terms: "AGREEMENT TO TERMS",
        agreed: "I Agree",
        selectCountry: "Select Country",
        cancelAgree: "Cancel",
        termsandCondition: "Terms and Conditions",
        registered: "You are now registered",
        registerPage: {
          setUp: "Set up and verify your profile to book for OCR's Isola KLCC: Virtual Tour.",
          fullName: "Full Name",
          mobile: "Mobile No.",
          nationality: "Country",
          email: "Email",
          password: "Password",
          confirmPW: "Confirm Password",
          preferredAppoint: "Preferred Apointment Date",
        },
        errors: {
          tnc: "The Terms & Conditions must be accepted.",
          nationality: "Please select country.",
          appoint: "Please select appointment time and date.",
          password: "Password is required.",
          passwordMismatch: "Password mismatch.",
          email: "Email is required.",
          email2: "Email must be a valid email.",
          name: "Name is required."
        },
        misc: {
          registered: "You are now verified. OCR sales agent will be contacting you according to the booking date!",
          otp: 'Too many resend, please try again later.',
          otp2: "OTP code has been sent to your mobile phone, please key in the four digit code to the input field to verify.",
          otp3: "OTP Request.",
          otpResend: "Have not received OTP yet? Click here to resend",
          otpCode: "Verify Code"
        }
    }


return en
}