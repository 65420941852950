import React, {useState} from "react"
import {
  Center,
  ChakraProvider,
  HStack,
  Text,
  VStack,
  Image
} from "@chakra-ui/react"
import '../src/App.css'
import {Switch, Route, useHistory} from "react-router-dom";
import Landing from "./pages/Landing";
import customTheme from "./utils/theme";
import Header from "./components/sections/Header";

import Chinese from './assets/china.png'
import English from './assets/uk.png'
import BG from './assets/BG.jpg'
import Register from "./pages/Register";
import {en} from './language/en';
import {cn} from './language/cn';
import 'dotenv'
import RegisterAgent from "./pages/RegisterAgent";
import RegisterProspectRef from "./pages/RegisterProspectRef";
import LoginProspectRef from "./pages/LoginProspect";
import LoginAgent from "./pages/LoginAgent";
import TemLink from "./pages/TempLink";


export const App = () => {
  const [languageData, setLanguageData] = useState<any>(en)
  const history = useHistory()

  function languageSel(language:string) {
    if (language == "english") {
      setLanguageData(en)
    } else if (language == "chinese") {
      if (language == "chinese") {
        setLanguageData(cn)
      }
    }
    history.push("/home")
  }

  function LanguagePageRef() {
    // let {id}:any = useParams()
    

    return (
      <Center h="100vh" >
      <HStack h="100%" justifyContent="center" alignItems="center">
      <VStack>
        <Image onClick={() => languageSel( "chinese")} cursor="pointer" w="50%" borderRadius="500px" boxShadow="2xl" draggable={false} src={Chinese} alt="en" />
        <Text color="white" fontWeight="bold">中文</Text>
        </VStack>
        <VStack>
        <Image onClick={() => languageSel( "english")} cursor="pointer" w="50%" borderRadius="500px" boxShadow="2xl" draggable={false} src={English} alt='cn' />
        <Text color="white" fontWeight="bold">English</Text>
        </VStack>

      </HStack>
      {/* This provides the BG */}
      <Center filter="blur(4px)" backgroundImage={BG} h="100vh" w="100%" zIndex="-1" position="fixed"></Center>
      </Center>
    )
  }


  return (
  <ChakraProvider theme={customTheme}>
    {/* <Header/>  */}
      <Switch>

      <Route exact path="/">
      <Center h="100vh" >
      <HStack h="100%" justifyContent="center" alignItems="center">
      <VStack>
        {/* <Image  cursor="pointer" w="50%" borderRadius="500px" boxShadow="2xl" draggable={false} src={Chinese} alt="en" /> */}
        <Text py="5"  mx="5vw"borderRadius="2vw" align="center" bg="#3D4962" w="24vw" fontSize="3vw" onClick={() => languageSel("chinese")} cursor="pointer" color="white" fontWeight="bold">中文</Text>
        </VStack>
        <VStack>
        {/* <Image  w="50%" borderRadius="500w" boxShadow="2xl" draggable={false} src={English} alt='cn' /> */}
        <Text py="5" mx="5vw" borderRadius="2vw" bg="#3D4962" align="center"  w="24vw" fontSize="3vw" onClick={() => languageSel("english")} cursor="pointer" color="white" fontWeight="bold">English</Text>
        </VStack>

      </HStack>
      {/* This provides the BG */}
      <Center filter="blur(4px)" backgroundImage={BG} h="100vh" w="100%" zIndex="-1" position="fixed"></Center>
      </Center>
      </Route>
        <Route path="/home">
          <Landing languageData={languageData} languageSel={languageSel} />
        </Route>
        {/* <Route path="/login">
          <LoginForm/>
        </Route> */}
        <Route path="/register">
          <Register languageData={languageData} />
        </Route>

        <Route path="/register-agent">
          <RegisterAgent languageData={languageData}/>
        </Route>

        <Route path="/register-prospect/:id">
          <RegisterProspectRef languageData={languageData}/>
        </Route>

        <Route path="/login-prospect">
          <LoginProspectRef languageData={languageData}/>
        </Route>

        <Route path="/login-agent">
        <LoginAgent languageData={languageData}/>
        </Route>



        {/* For referral, separated. */}
        <Route path="/code/:id" children={<LanguagePageRef/>} />
        <Route path="/register/:id" children={<Register  />}/>
        <Route path="/verify-link" children={<TemLink/>}/>


      </Switch>

  </ChakraProvider>)
}
